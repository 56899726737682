// COLORS
$primaryColor: #000032;
$secondarycolor: #1a66ff;
$thirdColor: #adadad;
$textColor: #fff;
// :root{
//     --primaryColor: #000032;
//     --secondarycolor: #1a66ff;
//     --thirdColor: #adadad;
//     --textColor: #fff;
// }

// FUNCTION
@mixin flex() {
  display: flex;
  align-items: center;
}
@mixin flexA() {
  display: flex;
  justify-content: space-around;
}
@mixin flexB() {
  display: flex;
  justify-content: space-between;
}
@mixin flexAC() {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@mixin flexBC() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin shadowCard() {
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}

// =================== CONNECTORS TBALES ===================
table {
  width: 100%;
  text-align: left;
  padding: 2em;
  background-color: $textColor;
  border-radius: 2em;
  thead {
    th {
      color: $thirdColor;
      font-weight: normal;
    }
  }
  tbody {
    a {
      color: $primaryColor;
    }
    tr {
      td {
        padding: 1em 0;
        font-size: 14px;
        .main {
          @include flex();
          span {
            background-color: $secondarycolor;
            color: $textColor;
            clip-path: circle();
            margin-right: 0.5em;
            font-size: 17px;
            padding: 0.3em;
          }
          a {
            @include flex();
          }
        }
        .type {
          background-color: #1a66ff0c;
          color: $secondarycolor;
          padding: 0.3em 1em;
          border-radius: 2em;
        }
        .success,
        .dead,
        .running,
        .starting {
          background-color: #1a66ff0c;
          color: $secondarycolor;
          padding: 0.3em 1em;
          border-radius: 2em;
        }
        .success {
          background-color: rgba(5, 185, 53, 0.2);
          color: rgb(5, 185, 53);
        }
        .dead {
          background-color: rgba(245, 54, 47, 0.2);
          color: rgb(245, 54, 47);
        }
        .running,
        .starting {
          background-color: #1a66ff0c;
          color: $secondarycolor;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:last-child {
        }
      }
      td:last-child {
        button,
        a {
          background-color: transparent;
          padding: 0.7em 0.7em 0.5em 0.7em;
          clip-path: circle();
          span {
            font-size: 20px;
            color: $secondarycolor;
          }
          &:hover {
            background-color: #1a66ff0c;
          }
        }
      }
    }
  }
}

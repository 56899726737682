// COLORS
$primaryColor: #000032;
$secondarycolor: #1a66ff;
$thirdColor: #adadad;
$textColor: #fff;
:root {
  --primaryColor: #000032;
  --secondarycolor: #1a66ff;
  --thirdColor: #adadad;
  --textColor: #fff;
  --dashColor: #0000320b;
}

// FUNCTION
@mixin flex() {
  display: flex;
  align-items: center;
}
@mixin flexA() {
  display: flex;
  justify-content: space-around;
}
@mixin flexB() {
  display: flex;
  justify-content: space-between;
}
@mixin flexAC() {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@mixin flexBC() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin shadowCard() {
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}

// =============== ADD FORM ===============
.bg-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
}
.connector-form {
  z-index: 100;
  position: absolute; //fixed
  transform: translate(-50%, 0); //translate(-50%, -50%)
  left: 40%; //50%
  top: 0; // 50%
  padding: 2em;
  border-radius: 2em;
  width: 50%;
  background-color: $textColor;
  transition: 0.4s;
  .head {
    @include flexBC();
    h3 {
      font-weight: normal;
    }
    button {
      padding: 0.5em;
      clip-path: circle();
      background-color: #0000320b;
      color: $primaryColor;
      @include flex();
      span {
        font-size: 20px;
      }
    }
    div {
      @include flex();
      button {
        margin-left: 1em;
        padding: 0.5em 1em 0.5em 0.5em;
        clip-path: none;
        border-radius: 2em;
        background-color: rgba(246, 163, 38, 0.1);
        color: rgb(246, 163, 38);
        @include flex();
        p {
          font-size: 12px;
          margin-left: 0.5em;
        }
        span {
          font-size: 20px;
        }
      }
    }
  }
  .select-type {
    background-color: #00003218;
    border-radius: 1em;

   
    button {
      padding: 1em;
      background-color: transparent;
      color: $thirdColor;
      border-radius: 1em;
    }
    .active {
      background-color: $textColor;
      color: $secondarycolor;
    }
  }
  .form {
    margin: 1em 0;
    .inps {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2em;
    }
    .inp {
      margin: 0.5em 0;
      label {
        font-size: 13px;
        color: $thirdColor;
        display: block;
      }
      input,
      textarea,
      select {
        width: -webkit-fill-available;
        background-color: #00003218;
        padding: 1.2em;
        border-radius: 2em;
        margin: 0.5em 0;
      }
      select {
        width: 100%;
      }
      textarea {
        height: 100px;
      }
    }
    .required-error {
      font-size: 12px;
      padding: 0 1em;
      height: 17px;
      color: rgb(236, 50, 90);
    }
    .submit {
      @include flexBC();
      button,
      a {
        background-color: $secondarycolor;
        padding: 1em;
        border-radius: 2em;
        width: 100%;
        font-size: 14px;
        text-align: center;
        &:first-child {
          background-color: transparent;
          color: $thirdColor;
        }
      }
    }
  }
}

// =============== RUN LOG ===============
.run-log {
  width: 50%;
  .log {
    // height: 200px;
    // overflow: hidden;
    margin: 1em 0;
    background-color: #1a66ff10;
    color: $secondarycolor;
    padding: 1.5em;
    border-radius: 2em;
    display: inline-block;
    width: -webkit-fill-available;
    .infos {
      @include flexBC();
      .info {
        div {
          @include flex();
          width: fit-content;
          padding: 0.3em 1em;
          background-color: #1a66ff10;
          color: $secondarycolor;
          border-radius: 2em;
          span {
            font-size: 17px;
            margin-right: 0.5em;
          }
          h5 {
            font-size: 12px;
            font-weight: normal;
          }
        }
        p {
          font-family: monospace;
          margin: 1em;
          font-size: 13px;
        }
        .status {
          @include flex();
          span {
            font-size: 15px;
            margin-right: 0.5em;
            animation: 1s infinite linear rotation;
            @keyframes rotation {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
    hr {
      margin: 0.5em;
      border-color: rgba(0, 0, 0, 0.049);
    }
    .log-lines {
      height: 350px;
      overflow: auto;
      p {
        font-family: monospace;
        line-height: 2em;
        font-size: 12px;
        overflow: hidden;
        span {
          font-family: monospace;
        }
      }
    }
  }
  .form .submit .disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.run {
  position: relative;
  transform: translate(0);
  left: 0;
  top: 0;
  width: -webkit-fill-available;
  background-color: transparent;
  z-index: 1;
  .head {
    .back {
      @include flex();
      text-align: left;
      color: $primaryColor;
      background-color: #0000320b;
      border-radius: 2em;
      font-size: 13px;
      padding: 0.5em 2em;
      span {
        font-size: 15px;
      }
    }
  }
}

// =========== EMPTY =============
.empty {
  text-align: center;
  img {
    width: 300px;
  }
  h3 {
    margin-top: -2em;
    font-weight: normal;
    font-size: 15px;
  }
  p {
    font-size: 13px;
    color: $thirdColor;
  }
}

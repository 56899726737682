@import url("https://fonts.googleapis.com/css2?family=Bakbak+One&family=Bebas+Neue&family=El+Messiri&family=Poppins:wght@500&display=swap");

// COLORS
$primaryColor: #000032;
$secondarycolor: #1a66ff;
$thirdColor: #adadad;
$textColor: #fff;
// :root{
//     --primaryColor: #000032;
//     --secondarycolor: #1a66ff;
//     --thirdColor: #adadad;
//     --textColor: #fff;
//     --dashColor : #0000320b;
// }

// FUNCTION
@mixin flex() {
  display: flex;
  align-items: center;
}
@mixin flexA() {
  display: flex;
  justify-content: space-around;
}
@mixin flexB() {
  display: flex;
  justify-content: space-between;
}
@mixin flexAC() {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@mixin flexBC() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin shadowCard() {
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}

// =================  RESPONSIVE DASHBOARD  =================
@media (max-width: 1280px) {
  .connector-cards,
  .dataset-cards,
  .etl-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1200px) {
  .dashboard {
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
      padding: 1em;
    }
    table td {
      border-bottom: 1px solid #0000320b;
      display: block;
      font-size: 0.8em;
      text-align: right;
      .main {
        display: block;
      }
      &:nth-child(2) {
        width: auto;
        // text-align: center;
        color: $primaryColor;
      }
    }

    table td::before {
      content: attr(data-label);
      float: left;
      color: $thirdColor;
    }

    .connector-form {
      width: 70%;
    }
  }
}

@media (max-width: 1060px) {
  .dashboard {
    margin-left: 116px;
    header {
      left: 116px;
    }
    .side-bar {
      width: 50px;
      .logo {
        justify-content: center;
        div {
          display: none;
        }
        img {
          width: 40px;
        }
      }
      .links ul li {
        width: max-content;
        a,
        button {
          span {
            margin: 0;
          }
          p {
            display: none;
          }
        }
        .active {
          border: 0;
          border-bottom: 3px solid $secondarycolor;
        }
      }
    }
  }
}

@media (max-width: 920px) {
  .dashboard {
    .flow .node-actions {
      flex-direction: column;
      .actions {
        margin-top: 1em;
      }
      .nodes {
        .nd {
          padding: 0.3em;
          p {
            font-size: 12px;
          }
          span {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 815px) {
  .dashboard {
    padding: 0;
    .connector-cards,
    .dataset-cards,
    .etl-cards {
      grid-template-columns: repeat(1, 1fr);
    }
    .flow .node-actions {
      padding-top: 2em;
    }
  }
}

@media (max-width: 600px) {
  .dashboard {
    margin-left: 0;
    header {
      left: 0;
    }
    .side-bar {
      left: -100%;
    }
    .connectors,
    .dataset,
    .etl {
      .top {
        h3 {
          font-size: 17px;
        }
        button {
          padding: 0.7em 1em;
          p {
            font-size: 12px;
          }
        }
      }
      .filter-style {
        flex-direction: column-reverse;
        .filter {
          margin-top: 1em;
          width: 85%;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .connector-form {
    width: 70%;
    left: 50%;
    .form .inps {
      grid-template-columns: 100%;
      gap: 0;
    }
  }
  .dashboard {
    .flow .node-actions {
      .actions {
        margin-top: 0.5em;
      }
      .nodes {
        flex-direction: column;
        .nd {
          margin-bottom: 1em;
        }
      }
    }
  }
}

// COLORS
// $primaryColor: #000032;
// $secondarycolor: #1a66ff;
// $thirdColor: #adadad;
// $textColor: #fff;
:root {
  --primaryColor: #000032;
  --secondarycolor: #3678fc;
  --thirdColor: #adadad;
  --textColor: #fff;
}


// FUNCTION
@mixin flex() {
  display: flex;
  align-items: center;
}
@mixin flexA() {
  display: flex;
  justify-content: space-around;
}
@mixin flexB() {
  display: flex;
  justify-content: space-between;
}
@mixin flexAC() {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@mixin flexBC() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin shadowCard() {
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}

// =================== CONNECTORS CARDS ===================
.connector-cards,
.dataset-cards,
.etl-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  .card {
    padding: 2em;
    background-color: var(--textColor);
    border-radius: 2em;
    .info {
      @include flex();
      span {
        background-color: var(--secondarycolor);
        color: var(--textColor);
        clip-path: circle();
        font-size: 30px;
        padding: 0.3em;
        margin-right: 0.3em;
      }
      div {
        h5 {
          font-weight: normal;
          color: var(--thirdColor);
          font-size: 13px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
    .more {
      @include flexBC();
      margin-top: 2em;
      div {
        padding: 0.5em 1.5em;
        background-color: #1a66ff0c;
        color: var(--secondarycolor);
        @include flex();
        font-size: 13px;
        border-radius: 2em;
        margin: 0 0.5em;
        span {
          margin-right: 0.5em;
          font-size: 15px;
        }
      }
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Bakbak+One&family=Bebas+Neue&family=El+Messiri&family=Poppins:wght@500&display=swap");

// COLORS
$primaryColor: #000032;
$secondaryColor: #1a66ff;
$secondarycolor: #1a66ff;
$thirdColor: #adadad;
$textColor: #fff;
:root {
  --primaryColor: #000032;
  --secondarycolor: #1a66ff;
  --thirdColor: #adadad;
  --textColor: #fff;
  --dashColor: #0000320b;
}

// FUNCTION
@mixin flex() {
  display: flex;
  align-items: center;
}
@mixin flexA() {
  display: flex;
  justify-content: space-around;
}
@mixin flexB() {
  display: flex;
  justify-content: space-between;
}
@mixin flexAC() {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@mixin flexBC() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin shadowCard() {
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}

// DEFAULT
* {
  padding: 0;
  margin: 0;
  font-family: "Poppins";
}
html {
  scroll-behavior: smooth;
}
input,
select,
textarea {
  border: none;
  outline: none;
  font-family: "Poppins";
  background-color: $textColor;
  color: $primaryColor;
  transition: 0.5s;
}
button {
  border: none;
  outline: none;
  font-family: "Poppins";
  background-color: $secondarycolor;
  color: $textColor;
  cursor: pointer;
  transition: 0.5s;
}
a {
  text-decoration: none;
  color: $textColor;
  transition: 0.5s;
}
ul {
  list-style: none;
}

// BODY
body {
  // background-color: $textColor;
  // color: $primaryColor;
  font-family: "Poppins";
  background-color: #f6f6f6;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e3e3e3;
  border-radius: 2em;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8888886b;
  border-radius: 2em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5555553e;
  border-radius: 2em;
}

// ****************** PRIVATE PAGES ******************

// =================  DASHBOARD  =================
.dashboard {
  margin-left: 265px;
  position: relative;
  top: 80px;
  padding: 2em;

  // SIDE BAR
  .side-bar {
    background-color: $textColor;
    position: fixed;
    height: 100vh;
    width: 200px;
    padding: 2em;
    border-right: 2px solid rgba(128, 128, 128, 0.1);
    transition: 0.5s;
    z-index: 99;
    left: 0;
    top: 0;
    .logo {
      @include flex();
      img {
        width: 50px;
        border-radius: 1em;
        margin-right: 0.5em;
      }
      span {
        font-size: 12px;
        color: $thirdColor;
      }
    }
    .tools {
      margin-top: 0 !important;
    }
    .links {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 80%;
      ul {
        margin-top: 4em;
        padding: 0 1em;
        @include flexBC();
        flex-direction: column;
        &:first-child {
          height: 100%;
        }
        section {
          width: 100%;
        }
        li {
          width: 100%;
          a,
          button {
            @include flex();
            color: $thirdColor;
            background-color: transparent;
            padding: 0.8em 0;
            margin: 0.5em 0;
            border-right: 4px solid transparent;
            span {
              margin-right: 0.5em;
            }
            p {
              font-size: 14px;
            }
            &:hover {
              color: $secondaryColor;
            }
          }
          .active {
            color: $secondaryColor;
            border-right: 4px solid $secondaryColor;
          }
        }
      }
    }
  }
  // HEADER
  header {
    position: fixed;
    @include flexBC();
    flex-direction: row-reverse;
    border-bottom: 2px solid rgba(128, 128, 128, 0.1);
    background-color: $textColor;
    top: 0;
    left: 265px;
    width: -webkit-fill-available;
    padding: 1em;
    height: 50px;
    z-index: 99;
    transition: 0.5s;
    .side-controll {
      background-color: $textColor;
      color: $thirdColor;
      padding: 0.5em;
      border-radius: 0.5em;
      span {
        font-size: 30px;
      }
    }
    .admin-info {
      @include flex();
      img {
        width: 35px;
        clip-path: circle();
      }
      .actions {
        button {
          background-color: $textColor;
          color: $thirdColor;
          padding: 0.5em;
          clip-path: circle();
          &:hover {
            background-color: #0000320b;
          }
        }
      }
      .material-symbols-outlined {
        padding: 0 0.2em;
        color: $thirdColor;
      }
      .admin-profile {
        @include flex();
        padding: 0 1em 0 0.5em;
        border-left: 2px solid rgba(128, 128, 128, 0.1);
        .more {
          line-height: 1em;
          p {
            font-size: 13px;
          }
          span {
            font-size: 11px;
            color: $thirdColor;
          }
        }
      }
    }
  }
}

// =================  COONNECTOR & DATASET & ETL PAGE  =================
.dashboard .connectors,
.dataset,
.etl,
.workflow {
  padding: 2em;
  .top {
    @include flexBC();
    h3 {
      font-weight: normal;
    }
    button {
      @include flex();
      padding: 1em 1.5em;
      border-radius: 2em;
      span {
        margin-right: 0.5em;
      }
    }
  }
  .filter-style {
    @include flexBC();
    margin: 2em 0;
    .filter {
      @include flex();
      background-color: #0000320b;
      border-radius: 1em;
      padding: 0 1em;
      input {
        padding: 1.2em 1em;
        width: 100%;
        background-color: transparent;
      }
      span {
        color: $secondaryColor;
        margin-right: 0.5em;
      }
      width: 50%;
    }
    .style {
      @include flex();
      padding: 0.5em;
      background-color: #0000320b;
      border-radius: 1em;
      button {
        background-color: transparent;
        color: $thirdColor;
        border-radius: 0.5em;
        padding: 0.3em 1em 0 1em;
      }
      .grid {
        background-color: $textColor;
      }
    }
  }
}

// =================  FLOW PAGE  =================
.dashboard .flow {
  height: 75vh;
  // =================  ADD NODE STYLE  ==============
  .node-actions {
    @include flexBC();
    .actions {
      @include flex();
      button {
        @include flex();
        padding: 0.7em;
        border-radius: 1em;
        margin-left: 1em;
        background-color: #1a66ff10;
        color: $secondarycolor;
      }
    }
    .nodes {
      @include flex();
      .nd {
        margin: 0 1em;
        @include flex();
        padding: 0.5em;
        width: 150px;
        border-radius: 2em;
        background-color: $textColor;
        cursor: pointer;
        &:hover span {
          background-color: $secondarycolor;
          color: $textColor;
        }
        span {
          font-size: 18px;
          background-color: #1a66ff10;
          color: $secondarycolor;
          clip-path: circle();
          padding: 0.5em;
          margin-right: 0.5em;
          transition: 0.5s;
        }
        &:nth-child(2) {
          span {
            background-color: #fb8e6715;
            color: #fb8f67;
          }
        }
        &:nth-child(2):hover span {
          background-color: #fb8f67;
          color: $textColor;
        }
        &:nth-child(3) {
          span {
            background-color: #49a07715;
            color: #49a078;
          }
        }
        &:nth-child(3):hover span {
          background-color: #49a078;
          color: $textColor;
        }
        p {
          font-size: 13px;
        }
      }
    }
  }
  // =================  NODES STYLE  =================
  .node {
    padding: 1em;
    background-color: $textColor;
    @include flexBC();
    width: 250px;
    border-radius: 1em;
    animation: first 5s linear;
    @keyframes first {
      0% {
        outline: 5px solid #1a66ff0c;
      }
      25% {
        outline: 20px solid #1a66ff0c;
      }
      50% {
        outline: 5px solid #1a66ff0c;
      }
      75% {
        outline: 20px solid #1a66ff0c;
      }
      100% {
        outline: 5px solid #1a66ff0c;
      }
    }
    .text {
      @include flex();
      span {
        padding: 0.5em;
        background-color: $secondarycolor;
        color: $textColor;
        border-radius: 0.5em;
      }
      .info {
        margin-left: 0.5em;
        p {
          font-size: 14px;
        }
        h5 {
          font-size: 12px;
          color: $thirdColor;
          font-weight: normal;
        }
      }
    }
    button {
      clip-path: circle();
      color: $thirdColor;
      background-color: transparent;
      padding: 0.3em;
      @include flex();
      &:hover {
        background-color: #0000320b;
      }
    }
    .actions {
      position: absolute;
      background-color: $textColor;
      border-radius: 1em;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin-left: 250px;
      margin-top: 150px;
      .act {
        padding: 1em;
        @include flex();
        color: $thirdColor;
        cursor: pointer;
        transition: 0.4s;
        &:hover {
          background-color: #0000320b;
        }
        p {
          font-size: 13px;
        }
        span {
          font-size: 18px;
          margin-right: 0.5em;
        }
      }
      .act:nth-child(1) {
        padding: 1em 3em 0.5em 1em;
        border-radius: 1em 1em 0 0;
      }
      .act:nth-child(2) {
        padding: 0.5em 3em 1em 1em;
        border-radius: 0 0 1em 1em;
      }
    }
  }
  .transform {
    .text span {
      background-color: #fb8f67;
    }
  }
  .output {
    .text span {
      background-color: #49a078;
    }
  }
  // =================  SETTINGS ==============
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    filter: blur(2px);
    z-index: 95;
  }
  .settings {
    position: fixed;
    right: -100%;
    top: 83px;
    background-color: $textColor;
    border-radius: 2em 0 0 2em;
    padding: 2em;
    height: -webkit-fill-available; //calc(100vh - 150px)
    width: 25%;
    // width: 280px;
    z-index: 101;
    transition: 0.4s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .head {
      @include flexBC();
      button {
        @include flex();
        background-color: transparent;
        color: $thirdColor;
        span {
          font-size: 18px;
        }
      }
      h3 {
        font-weight: normal;
        font-size: 15px;
      }
    }
    .setting-content {
      overflow-y: scroll;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      .inps {
        margin: 1em 0;
        .inp {
          margin-bottom: 1em;
          label {
            display: block;
            font-size: 14px;
            color: $thirdColor;
          }
          input,
          textarea,
          select {
            width: -webkit-fill-available;
            margin: 0.5em 0;
            padding: 1em;
            border-radius: 1em;
            background-color: #0000320b;
            resize: none;
            word-break: break-all;
          }
          textarea {
            height: 100px;
          }
          .sql-source {
            font-size: 13px;
          }
          .required-error {
            font-size: 12px;
            padding: 0 1em;
            height: 17px;
            color: rgb(236, 50, 90);
          }
        }
        .sql-text {
          overflow: hidden;
          height: 100px;
          position: relative;
          font-size: 13px;
          border-radius: 1em;
          margin-top: -138px;
          padding: 1em;
          width: -webkit-fill-available;
          word-break: break-all;
          z-index: -1;
        }
        .sql-keyword-1 {
          color: $secondarycolor;
        }
        .sql-keyword-2 {
          color: rgb(37, 143, 60);
        }
        .sql-keyword-3 {
          color: rgb(236, 50, 90);
        }
        .inp-box {
          @include flex();
          p {
            font-size: 14px;
            color: $thirdColor;
          }
        }
      }
      .submit {
        @include flexBC();

        button {
          width: 100%;
          padding: 1em;
          border-radius: 2em;
          &:first-child {
            background-color: transparent;
            color: $thirdColor;
          }
        }
      }
    }
  }
  .open {
    right: 0;
  }
}

// =================  AUTH PAGE  =================
.auth .auth-user {
  display: grid;
  grid-template-columns: 40% 60%;
  height: 100vh;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
  .left {
    background-color: var(--dashColor);
    color: $primaryColor;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0 4em 4em 0;
    .text {
      text-align: left;
      p {
        font-size: 13px;
        opacity: 0.5;
        margin: 0.5em 0;
      }
    }
    img {
      width: 350px;
    }
    .nodes {
      width: 100%;
      margin: 2em 0;
      // Colors
      $bg-color: hsl(256, 33, 10);
      $dot-color: black;

      // Dimensions
      $dot-size: 1px;
      $dot-space: 22px;

      background: linear-gradient(
            90deg,
            #f4f4f6 ($dot-space - $dot-size),
            transparent 1%
          )
          center,
        linear-gradient(#f4f4f6 ($dot-space - $dot-size), transparent 1%) center,
        $dot-color;
      background-size: $dot-space $dot-space;
      .node {
        padding: 0.5em 1em;
        background-color: $textColor;
        border-radius: 3em;
        @include flex();
        width: fit-content;
        margin: 2em 0;
        span {
          margin-right: 0.5em;
          background-color: #1a66ff10;
          color: $secondaryColor;
          padding: 0.3em;
          clip-path: circle();
          font-size: 18px;
        }
        p {
          font-size: 13px;
        }
      }
      .nd-transform {
        display: flex;
        justify-content: center;
        &::before {
          content: "";
          position: absolute;
          width: 2px;
          height: 120px;
          background-color: rgba(128, 128, 128, 0.2);
          margin-left: -14em;
          margin-top: -4em;
          transform: rotate(-50deg);
        }
        span {
          background-color: $secondaryColor;
          color: $textColor;
        }
      }
      .nd-output {
        display: flex;
        justify-content: right;
        &::before {
          content: "";
          position: absolute;
          width: 2px;
          height: 120px;
          background-color: rgba(128, 128, 128, 0.2);
          margin-right: 10em;
          margin-top: -4em;
          transform: rotate(-50deg);
        }
      }
    }
  }
  .right {
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .logo {
      @include flex();
      justify-content: center;
      h3 {
        font-weight: normal;
      }
      img {
        width: 40px;
        margin-right: 0.2em;
      }
      margin: 1em 0;
    }
    .select-auth {
      padding: 0.5em 0;
      border-radius: 1em;
      background-color: #f3f5f7;
      width: 50%;
      margin: 1em 0;
      @media (max-width: 767px) {
        width: 100%;
      }
      @include flex();
      button {
        padding: 1em;
        border-radius: 1em;
        width: 50%;
        margin: 0 0.5em;
        background-color: transparent;
        color: $thirdColor;
      }
      .active {
        background-color: $textColor;
        color: $primaryColor;
      }
    }
    form {
      width: 50%;
      @media (max-width: 767px) {
        width: 100%;
      }
      .inp {
        @include flex();
        background-color: #f3f5f7;
        border-radius: 1em;
        margin: 1em 0;
        padding: 0 1em;
        input {
          padding: 1.2em;
          background-color: transparent;
          width: -webkit-fill-available;
        }
        span {
          opacity: 0.5;
        }
      }
      .submit {
        button {
          padding: 1.2em;
          border-radius: 1em;
          width: 100%;
        }
      }
      p {
        text-align: center;
        font-size: 12px;
        opacity: 0.5;
        margin: 1em;
      }
    }
  }
}

// =================  Cron ==============
.cron {
  margin: 0.5em;
  .cron-result {
    text-align: center;
    font-size: 13px;
    margin: 0 0 1.5em 0;
    color: $thirdColor;
  }
  .select-cron {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    background-color: #0000320b;
    padding: 0.3em;
    border-radius: 1em;
    button {
      font-size: 11px;
      padding: 0.5em;
      background-color: transparent;
      color: $thirdColor;
      border-radius: 1em;
    }
    .selected {
      background-color: $textColor;
    }
  }
  .selector {
    margin: 1em 0;
    display: flex;
    flex-wrap: wrap;
    button {
      @include flex();
      padding: 0.3em;
      border-radius: 2em;
      font-size: 10px;
      padding: 0.5em 0.7em;
      margin: 0.5em;
      background-color: #1a66ff10;
      color: $secondaryColor;
      span {
        font-size: 15px;
        margin-left: 0.3em;
      }
    }
    .selected {
      background-color: $secondaryColor;
      color: $textColor;
    }
  }
}

// <<<<<<<<<<<<<<<< MESSAGES STYLE >>>>>>>>>>>>>>>>>>>>
.msg,
.error-msg {
  position: fixed;
  transform: translate(-50%, -50%) scale(0);
  left: 50%;
  top: 0;
  border-radius: 1em;
  padding: 1em;
  @include flexBC();
  width: 400px;
  background-color: $textColor;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 103;
  border-left: 5px solid rgb(61, 201, 87);
  transition: 0.4s;
  .text {
    @include flex();
    span {
      padding: 0.2em;
      background-color: rgb(61, 201, 87);
      color: $textColor;
      clip-path: circle();
      margin: 0 0.5em;
    }
    div {
      p {
        font-size: 14px;
      }
      h5 {
        font-size: 12px;
        font-weight: normal;
        color: $thirdColor;
      }
    }
  }
  button {
    background-color: transparent;
    color: $thirdColor;
    clip-path: circle();
    padding: 0.3em;
    @include flex();
    &:hover {
      background-color: #0000320b;
    }
  }
}
// ERROR MESSAGE
.error-msg {
  border-left: 5px solid rgb(238, 44, 70);
  .text {
    span {
      background-color: rgb(238, 44, 70);
    }
  }
}

// POP MESSGAE CONTROLL
.success-msg {
  transform: translate(-50%, -50%) scale(1);
  top: 10%;
}

// <<<<<<<<<<<<<<<< POP STYLE >>>>>>>>>>>>>>>>>>>>
// DELETE
.pop {
  .delete-check {
    position: fixed;
    transform: translate(-50%, -50%) scale(0);
    left: 50%;
    top: 50%;
    padding: 2em;
    background-color: $textColor;
    color: $secondaryColor;
    border-radius: 2em;
    text-align: center;
    z-index: 101;
    transition: 0.3s;
    span {
      font-size: 70px;
      position: relative;
      animation: move infinite linear 3s;
      background-color: #1a66ff0c;
      padding: 0.3em;
      clip-path: circle();
    }
    h3 {
      margin-top: 1em;
      font-weight: normal;
    }
    p {
      color: $thirdColor;
      font-size: 14px;
      margin-bottom: 1em;
    }
    .actions {
      @include flexBC();
      margin-top: 2em;
      button {
        padding: 0.7em 2em;
        border-radius: 2em;
        width: 100%;
        margin: 0 0.5em;
      }
      button:last-child {
        background-color: #1a66ff0c;
        color: $secondaryColor;
      }
    }
    @keyframes move {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  .show {
    transform: translate(-50%, -50%) scale(1);
  }
}

// POP BACKGROUND
.pop-bg {
  background-color: #0000005c;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  visibility: hidden;
  transition: 0.3s;
}
.bg {
  visibility: visible;
}

// <<<<<<<<<<<<<<<< LOADER STYLE >>>>>>>>>>>>>>>>>>>>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #e9e9ed;
  color: $textColor;
  z-index: 200;
  width: 100%;
  height: 100vh;
  @include flex();
  justify-content: center;
  img {
    filter: hue-rotate(180deg);
    width: 250px;
  }
}

.logs-loader {
  @include flex();
  justify-content: center;
  height: 200px;
  .log-loader {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: #1a66ff0c;
    overflow: hidden;
    border-radius: 2em;
    &::after {
      content: "";
      width: 192px;
      height: 4.8px;
      background: $secondaryColor;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      border-radius: 2em;
      animation: animloader 1s linear infinite;
    }
  }
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}

// <<<<<<<<<<<<<<<< LOADER STYLE >>>>>>>>>>>>>>>>>>>>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.3em;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 20px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.3em;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

// <<<<<<<<<<<<<<<< ERROR 404 PAGE >>>>>>>>>>>>>>>>>>>>
.error-page {
  @include flexBC();
  justify-content: center;
  height: 100vh;
  img {
    width: 400px;
    filter: opacity(0.5);
  }
}

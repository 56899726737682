
// COLORS
$primaryColor: #f8f8f9;
$secondarycolor: #3b7bfa;
$thirdColor: #8B8D93;
$fourthColor: #fff;
$textColor: #1a1a1a;
// :root{
//     --primaryColor: #000032;
//     --secondarycolor: #3678fc;
//     --thirdColor: #adadad;
//     --textColor: #fff;
// }


// FUNCTION
@mixin flex(){
    display: flex;
    align-items: center;
}
@mixin flexA(){
    display: flex;
    justify-content: space-around;
}
@mixin flexB(){
    display: flex;
    justify-content: space-between;
}
@mixin flexAC(){
    display: flex;
    align-items: center;
    justify-content: space-around;
}
@mixin flexBC(){
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@mixin shadowCard(){
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
}




// =================== ADMIN ===================
.admin{
    background-color: $primaryColor;
    color: $textColor;
    height: 100%;
    min-height: 100vh;
    display: flex;
    // *************** SIDE BAR ***************
    .side-bar{
        background-color: $textColor;
        color: $primaryColor;
        padding: 2em 1em;
        width: 230px;
        @include flexBC();
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        height: -webkit-fill-available;
        z-index: 99;
        .info{
            width: 100%;
        }
        // LOGO
        .logo{
            @include flex();
            h3{
                font-weight: normal;
                span{
                    font-size: 25px;
                    background-color: $primaryColor;
                    color: $textColor;
                    border-radius: .5em;
                    padding: 0 .3em;
                    margin-right: .5em;
                }
            }
        }
        h5{
            font-weight: normal;
            color: $thirdColor;
            text-transform: uppercase;
        }
        .links{
            margin-top: 3em;
            ul{
                li{
                    a{
                        padding: 1em ;
                        margin: 1em 0;
                        @include flex();
                        font-size: 13px;
                        border-radius: 1em;
                        color: $thirdColor;
                        // transition: .4s;
                        span{
                            margin-right: .5em;
                        }
                        &:hover{
                            background-image: linear-gradient(-45deg, #8b8d9312, #8b8d9325) ;
                            color: $primaryColor;
                        }
                    }
                    .selected{
                        color: $primaryColor;
                    }
                }
            }
        }
        .profile{
            width: 100%;
            .admin-card{
                .card{
                    @include flex();
                    margin: 1.5em 0;
                    p{
                        font-size: 14px;
                    }
                    span{
                        font-size: 12px;
                        color: $thirdColor;
                    }
                    img{
                        clip-path: circle();
                        width: 45px;
                        margin-right: .5em;
                    }
                }
                button{
                    @include flex();
                    background-color: transparent;
                    background-image: linear-gradient(-45deg, #8b8d9312, #8b8d9325) ;
                    color: $primaryColor;
                    padding: 1em;
                    border-radius: 1em;
                    font-size: 13px;
                    width: 100%;
                    span{
                        margin-right: .5em;
                    }
                }
            }
        }
    }
    // *************** ADMIN CONTENT ***************
    .admin-content{
        width: 100%;
        position: relative;
        margin-left: 260px;
         // *************** HEADER ***************
        header{
            position: fixed;
            width: -webkit-fill-available;
            z-index: 98;
            padding: 1.5em 2em;
            background-color: $fourthColor;
            @include flexBC();
            box-shadow: rgba(208, 208, 208, 0.1) 0px 7px 29px 0px;
            .hello{
                h3{
                    font-weight: normal;
                }
                p{
                    font-size: 13px;
                    color: $thirdColor;
                }
            }
            .options{
                @include flex();
                .search{
                    margin: 0 1em;
                    @include flex();
                    background-color: $primaryColor;
                    border-radius: 1em;
                    padding: 0 .5em 0 1em;
                    box-shadow: inset rgba(172, 172, 172, 0.1) 0px 7px 29px 0px;
                    input{
                        padding: 1em .5em;
                        background-color: transparent;
                    }
                    button{
                        box-shadow: rgba(172, 172, 172, 0.1) 0px 7px 29px 0px;
                        background-color: $fourthColor;
                        color: $textColor;
                        @include flex();
                        border-radius: .5em;
                        padding: .5em 1em .5em .7em;
                        span{
                            font-size: 17px;
                            margin-right: .5em;
                            p{
                                font-size: 13px;
                            }
                        }
                    }
                }
                .settings{
                    background-color: transparent;
                    @include flex();
                    color: $textColor;
                }
            }
        }
        // *************** PANEL ***************
        .panel{
            padding: 2em;
            margin-top: 7em;
            .table-info{
                padding: 2em;
                background-color: $fourthColor;
                border-radius: 1em;
                box-shadow: rgba(204, 204, 204, 0.1) 0px 2px 8px 0px;
                .title-card{
                    padding: 0 1em;
                    h3{
                        font-weight: normal;
                    }
                    border-left: 10px solid $textColor;
                }
            }
        }
        // ++++++++++++ OVERVIEW PAGE +++++++++++++
        .overview{
            .statistics{
                display: grid;
                grid-template-columns: 60% 37%;
                gap: 2em;
                margin-bottom: 2em;
            }
            .pie{
                height: 100%;
                @include flexAC();
                flex-direction: column;
                .circle{
                    border: 16px solid #f3f3f3;
                    border-radius: 50%;
                    border-top: 16px solid $textColor;
                    width: 120px;
                    height: 120px;
                    @include flex();
                    justify-content: center;
                    h3{
                        font-weight: normal
                    }
                }
                .info{
                    @include flexAC();
                    div{
                        @include flex();
                        margin: 0 1em;
                        span{
                            margin-right: .3em;
                        }
                        p{
                            font-size: 12px;
                        }
                        &:nth-child(1){
                            color: $thirdColor;
                        }
                    }
                }
            }
        }
        // ++++++++++++ PLAYGROUND PAGE +++++++++++++
        .playground{
            .add{
                background-color: $textColor;
                color: $primaryColor;
                padding: 1em;
                border-radius: 1em;
                @include flex();
                margin: 0 0 2em 1em;
                p{
                    font-size: 12px;
                }
                span{
                    font-size: 18px;
                    margin-right: .5em;
                }
            }
            .playground-form{
                border-radius: 1em;
                .inp{
                    input, select{
                        border-radius: 1em;
                    }
                }
                .switch{
                    margin-top: .5em;
                    input:checked + .slider {
                        background-color: $textColor;
                        border-radius: .5em;
                      }
                      
                      input:focus + .slider {
                        box-shadow: 0 0 1px $textColor;
                    }
                }
                .options{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin: .5em 0;
                    button{
                        @include flex();
                        margin: .5em;
                        border-radius: 1em;
                        padding: .5em 1em;
                        font-size: 12px;
                        background-color: #8b8d9325;
                        color: $textColor;
                    }
                    .selected{
                        background-color: $textColor;
                        color: $primaryColor;
                    }
                }
                .submit{
                    button:last-child{
                        background-color: $textColor;
                        color: $primaryColor;
                        border-radius: 1em;
                    }
                }
            }
        }
    }
}


// =================== ADMIN AUTH ===================
.auth .auth-admin{
    .left .nodes{
        .nd-source, .nd-output{
            .node{
                span{
                    background-color: #8b8d9325;
                    color: $textColor;
                }
            }
        }
        .nd-transform{
            .node{
                span{
                    background-color: $textColor;
                }
            }
        }
    }
    .right{
        .logo{
            h3{
                @include flex();
                span{
                    background-color: $textColor;
                    color: $primaryColor;
                    padding: .1em .4em;
                    border-radius: .5em;
                    font-size: 25px;
                    font-weight: bold;
                    margin-right: .2em;
                }
            }
        }
        form{
            .submit button{
                background-color: $textColor;
            }
        }
    }
}


// =================== CONNECTORS TBALES ===================
.admin-tables{
    width: 100%;
    text-align: left;
    padding: 2em 1em;
    background-color: var(--textColor);
    border-radius: 2em;
    thead{
        th{
            color: $textColor;
            font-weight: normal;
            font-size: 14px;
        }
    }
    tbody{
        tr{
            td{
                color: $thirdColor;
                padding: 1em 0;
                font-size: 13px;
                .status{
                    background-color: transparent;
                    padding: .3em 1em;
                    border-radius: 2em; 
                    @include flex();
                    width: fit-content;
                    span{
                        font-size: 18px;
                        margin-right: .5em;
                        color: rgb(5, 185, 53);
                    }
                }
                .status-down{
                    // background-color: rgba(245, 54, 47, 0.2);
                    span{
                        transform: rotate(-180deg);
                        color: rgb(245, 54, 47);
                    }
                }
                .user-info{
                    @include flex();
                    span:first-child{
                        padding: .5em;
                        font-size: 17px;
                        background-color: #8b8d9325;
                        color: $textColor;
                        clip-path: circle();
                        margin-right: .5em;
                        font-weight: normal;
                    }
                    span{
                        font-size: 12px;
                        color: $thirdColor;
                    }
                    p{
                        color: $textColor;
                        font-size: 13px;
                    }
                    img{
                        width: 50px;
                        clip-path: circle();
                        margin-right: .5em;
                    }
                }
                button, a{
                    background-color: transparent;
                    padding: .7em .7em .5em .7em;
                    clip-path: circle();
                    span{
                        font-size: 20px;
                        color: $thirdColor;
                    }
                    &:hover{
                        background-color: #8b8d9312;
                    }
                }
                &:nth-child(2){
                    color: var(--thirdColor);
                    width: auto;
                }
                &:last-child{
                    display: revert;
                }
            }
        }
    }
}

// >>>>>>>>>>>>>>> POP ADMIN <<<<<<<<<<<<<<<
.pop-admin{
    .delete-check{
        span{
            color: $textColor;
            background-color: #8b8d9325 !important;
        }
        h3{
            color: $textColor;
        }
        .actions{
            button{
                background-color: $textColor;
            }
            .cancel{
                background-color: #8b8d9325 !important;
                color: $textColor !important;
            }
        }
    }
}